import React, { Fragment } from "react";
import { Route, Router } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { RoxanneMon } from "./components/RoxanneMon";
import { Auth } from "./Auth";
import "./PET.scss";
import "./custom.css";

export const auth = new Auth();

//To publish - install google sdk, then: gcloud builds submit --tag gcr.io/ttvpet/ttv-pet-react --project ttvpet

const handleAuthentication = async () => {
  //console.info("Handling Auth");
  //console.info(window.location);
  var query = parseQuery(window.location.search);
  // console.info("Query: " + query);
  var code = query["code"];
  //console.info("Code: " + code);

  await auth.SetJWT(code);

  //ToDo: why won't history remove query string?
  //history.replace("/");
  window.location.href = window.location.origin;
};

//ripped from SO: https://stackoverflow.com/a/13419367
function parseQuery(queryString) {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

export default function App() {
  return (
    <Layout>
      <Route exact path="/" component={Home} />
      <Route path="/home" component={Home} />
      <Route exact path="/roxannemon" component={RoxanneMon} />
      <Route
        path="/callback"
        render={() => {
          handleAuthentication();
          return <Fragment />;
        }}
      />
    </Layout>
  );
}
