import { Console } from "console";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { toast } from "react-toastify";

export class Auth {
  SetJWT = async (code: string) => {
    console.info("Setting JWT");
    let response = await fetch("/Twitch/Login?code=" + code);
    console.log(response);

    if (response.ok) {
      console.info("Response OK");
      let jwt = await response.text();
      toast.success("Login Success");
      console.log("JWT: " + jwt);
      localStorage.setItem("JWT", jwt);
    } else {
      console.error("Response failure");
      toast.error("Login Failed");
    }
  };

  getToken(): string | null {
    var tokenString = localStorage.getItem("JWT");

    if (tokenString === null || tokenString === undefined) return null;

    if (!this.isAuthenticated(tokenString)) return null;

    return tokenString;
  }

  logOut() {
    localStorage.removeItem("JWT");
  }

  isAuthenticated(tokenString: string): boolean {
    var decode = jwt_decode<JwtPayload>(tokenString);
    if (decode?.exp === undefined) return false;

    //var expires = new Date(decode.exp * 1000);
    var now = Date.now();
    return now > decode.exp;
  }

  fetch = (
    input: RequestInfo,
    init?: RequestInit,
    contentType?: string
  ): Promise<Response> => {
    let token = this.getToken();
    let headers = {};

    if (contentType) {
      headers = {
        Authorization: "Bearer " + token,
      };
    } else {
      headers = {
        Authorization: "Bearer " + token,
        "Content-Type": contentType,
      };
    }

    if (!init) {
      init = {
        credentials: "include",
        headers: headers,
      };
    } else {
      init.credentials = "include";
      init.headers = headers;
    }

    return fetch(input, init);
  };
}
