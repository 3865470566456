import React, { useEffect, useState } from "react";

export type SpriteProps = {
  SpriteSheet: string;
  FPS: number;
  Width: number;
  Height: number;
  Loop: boolean;
  EndCallback?: () => void;

  //optional
  StartFrame?: number;
  EndFrame?: number;
};

let framesUnchangedCount = 0;
let lastFrames = 0;
let last120: number[] = [];

export function Sprite(props: SpriteProps) {
  const [SheetWidth, SetSheetWidth] = useState(0);
  const [FrameCount, SetFrameCount] = useState(0);
  const [StartFrame] = useState(props.StartFrame ?? 0);
  //const [EndFrame, SetEndFrame] = useState(props.EndFrame ?? 0);
  const [CurrentFrame, SetCurrentFrame] = useState(0);
  const [ImageSource, SetImageSource] = useState(props.SpriteSheet);
  const [Animate, SetAnimate] = useState(false);

  let tick = 0;

  useEffect(() => {
    if (props.SpriteSheet) {
      SetImageSource(props.SpriteSheet);
    }
  }, [props, props.SpriteSheet]);

  useEffect(() => {
    if (SheetWidth > 0) SetFrameCount(SheetWidth / props.Width);
    else SetFrameCount(0);
    SetAnimate(!Animate);
  }, [SheetWidth, props.Width]);

  // useEffect(() => {
  //   if (FrameCount > 0) SetEndFrame(FrameCount - 1);
  //   else SetEndFrame(0);
  // }, [FrameCount]);

  useEffect(() => {
    requestAnimationFrame(animate);
    return () => {
      stopAnimation();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    stopAnimation();
    requestAnimationFrame(animate);
  }, [Animate]);

  function stopAnimation() {
    let id = requestAnimationFrame(function () {});
    while (id--) {
      window.cancelAnimationFrame(id);
    }
    SetCurrentFrame(0);
  }

  function imageLoaded(img) {
    SetSheetWidth(img.currentTarget.width);
  }

  function getAnimationFramesPerSecond(timestamp: number): number {
    let frames = 0;

    last120.push(timestamp / 1000.0); //convert to seconds

    if (last120.length < 120) return 60;

    var length = last120.length - 1;
    if (length > 120) last120 = last120.slice(length - 120, length);

    var sum = 0;
    for (let i = 1; i < last120.length; i++) {
      var first = last120[i - 1];
      var second = last120[i];
      sum += second - first;
      if (sum >= 1) {
        frames = i;
        break;
      }
    }

    console.info("Calculated FPS: " + frames);

    return frames;
  }

  function animate(timestamp: number) {
    let end = false;
    let endFrame = FrameCount - 1;

    let actualFrames = 60;
    //if frame count hasn't changed in 500 checks... assume it'll stay the same...
    if (framesUnchangedCount < 500) {
      actualFrames = getAnimationFramesPerSecond(timestamp);
      if (lastFrames === actualFrames) framesUnchangedCount++;
      else framesUnchangedCount = 0;
      lastFrames = actualFrames;
    } else {
      actualFrames = lastFrames;
    }

    //HACK: how to get requestAnimationFrame's actual rate?
    //      looks too fast locally, but right in obs with this
    if (tick >= Math.round(actualFrames / props.FPS)) {
      tick = 0;
      SetCurrentFrame((prevFrame) => {
        let newFrame = prevFrame + 1;

        if (newFrame > endFrame && props.Loop) {
          newFrame = StartFrame;
        } else if (newFrame > endFrame) {
          newFrame = endFrame;
          end = true;
        }

        return newFrame;
      });
    }

    if (end && props.EndCallback) {
      stopAnimation();
      props.EndCallback();
      return;
    } else {
      tick += 1;
      requestAnimationFrame(animate);
    }
  }

  const imagePosStyle = {
    transform: `translate(${-props.Width * CurrentFrame}px, ${0}px)`,
  };

  return (
    <div
      className={"sprite"}
      style={{
        width: props.Width + "px",
        height: props.Height + "px",
        overflow: "hidden",
      }}
    >
      <img
        src={ImageSource}
        onLoad={imageLoaded}
        style={imagePosStyle}
        alt=""
      />
    </div>
  );
}
