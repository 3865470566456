import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Button } from "@progress/kendo-react-buttons";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DabSheet from "../images/DabSheet.png";
import IdleSheet from "../images/IdleSheet.png";
import YumSheet from "../images/YumSheet.png";
import DealSheet from "../images/DealSheet.png";
import BorkSheet from "../images/BorkSheet.png";
import { Sprite } from "./Sprite";

const SHOW_DEBUG_OPTIONS: boolean = false;

export function RoxanneMon() {
  const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl("/hubs/twitch")
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.onreconnected((connectionId?: string) => {
        console.info("SignalR Reconnected");
        if (connectionId) console.info("Connection Id: " + connectionId);
        StartTTVPet();
        ReceiveActions();
      });

      connection.onclose((error) => {
        console.error("SignalR Closed");
        console.error(error);
      });

      connection
        .start()
        .then(() => {
          console.log("SignalR Connected!");
          StartTTVPet();
          ReceiveActions();
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  function StartTTVPet() {
    console.info("Starting TTV Pet");
    connection?.send("StartTTVPet", "Roxannemon");
  }

  function ReceiveActions() {
    if (connection) {
      //clear handler, prevent double listen
      console.info("Clearing actions");
      connection.off("ActionEvent", HandleAction);

      //new listener for actions
      console.info("Listening for actions");
      connection.on("ActionEvent", HandleAction);
    }
  }

  function HandleAction(action) {
    console.info("Action received:");
    console.info(action);
    if (action.action === "Roxanne Slorp") {
      Yum();
    } else if (action.action === "Bits") {
      Yum();
    } else if (action.action === "Roxanne Dab") {
      Dab();
    } else if (action.action === "Roxanne Deal") {
      Deal();
    } else if (action.action === "Roxanne Bork") {
      Bork();
    }
  }

  const [SpriteSheet, SetSpriteSheet] = useState(IdleSheet);
  const [Loop, SetLoop] = useState(true);
  const [FPS, SetFPS] = useState(5);

  useEffect(() => {
    Idle();
  }, []);

  function Idle() {
    Animate(IdleSheet, 2, true);
  }

  function Dab() {
    Animate(DabSheet, 3, false);
  }

  function Yum() {
    Animate(YumSheet, 4, false);
  }

  function Deal() {
    Animate(DealSheet, 2, false);
  }

  function Bork() {
    Animate(BorkSheet, 5, false);
  }

  function Animate(sheet: string, fps: number, loop: boolean) {
    SetSpriteSheet(sheet);
    SetFPS(fps);
    SetLoop(loop);
  }

  function AnimationEnded() {
    Idle();
  }

  return (
    <Fragment>
      {SHOW_DEBUG_OPTIONS && (
        <Fragment>
          <Button onClick={Dab}>Dab</Button>
          <Button onClick={Yum}>Yum</Button>
          <Button onClick={Deal}>Deal</Button>
          <Button onClick={Bork}>Bork</Button>
        </Fragment>
      )}

      <Sprite
        SpriteSheet={SpriteSheet}
        Width={500}
        Height={500}
        FPS={FPS}
        Loop={Loop}
        EndCallback={AnimationEnded}
      />
    </Fragment>
  );
}
