import React, { Fragment, useEffect, useState } from "react";
import { auth } from "../App";
import { NavMenu } from "./NavMenu";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardActions,
  CardImage,
  CardSubtitle,
  Avatar,
} from "@progress/kendo-react-layout";
import {
  Button,
  DropDownButton,
  FloatingActionButton,
} from "@progress/kendo-react-buttons";
import { Sprite } from "./Sprite";
import IdleSheet from "../images/IdleSheet.png";

export function Home() {
  useEffect(() => {
    GetUser();
  }, []);

  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState<any>(undefined);

  async function GetUser() {
    console.info("Getting user");
    try {
      let token = auth.getToken();
      //console.info("Token: " + token);
      if (token !== null) {
        let response = await auth.fetch("/Twitch/GetUser");
        if (response.ok) {
          console.info("Repsonse OK");
          let user = await response.json();
          console.log(user);
          setUser(user);
          //todo: check email is in list of beta users
          var canAccess =
            user.displayName === "ShaunaColor" ||
            user.displayName === "kodama_sama";
          setIsAuth(canAccess);
        }
      } else {
        console.error("Repsonse failure");
        //set unauth'd
        setIsAuth(false);
      }
    } catch (error) {
      console.error(error);
      logOut();
    }
  }

  function logOut() {
    auth.logOut();
    setIsAuth(false);
    setUser(undefined);
  }

  function TwitchLogin() {
    var location = window.location;
    var callbackUrl = location.protocol + "//" + location.host;

    //console.debug("CallbackUrl: " + callbackUrl);

    let url =
      "https://id.twitch.tv/oauth2/authorize" +
      "?client_id=hl999ab26pw4j2sxuwu47h3nb60khw" + //ttvpet - kodama_sama
      "&redirect_uri=" +
      callbackUrl +
      "/callback" +
      "&response_type=code" +
      "&scope=bits:read";

    //user:read:email

    window.open(url, "_self");
  }

  function Roxannemon() {
    window.location.href = window.location.origin + "/roxannemon";
  }

  return (
    <div className="homeWrapper">
      <Fragment>
        <Card className="homeCard">
          <CardHeader>
            {user && (
              <Avatar type="image" size="large" shape="circle">
                <img src={user.profileImageUrl} />
              </Avatar>
            )}
            <div className="welcomeText">
              Welcome to TTV Pet {user && <span>{user.displayName}</span>}!
            </div>
            {user && (
              <Button
                className="logOut"
                primary
                icon="logout"
                title="Log Out"
                onClick={logOut}
              />
            )}
          </CardHeader>
          <Sprite
            SpriteSheet={IdleSheet}
            Width={500}
            Height={500}
            FPS={2}
            Loop
          />
          <CardActions>
            {isAuth && (
              <Button primary onClick={Roxannemon} className="cardButton">
                Open Roxannemon
              </Button>
            )}
            {!isAuth && !user && (
              <Button primary onClick={TwitchLogin} className="cardButton">
                Login With Twitch
              </Button>
            )}
          </CardActions>
        </Card>
      </Fragment>
    </div>
  );
}
