import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { Container } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

export function Layout(props: any) {
  return (
    <Fragment>
      {/* <NavMenu /> */}
      <ToastContainer />
      <Container className="mainContainer">{props.children}</Container>
    </Fragment>
  );
}
